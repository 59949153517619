<template>
  <router-link :to="to" class="button" :class="classObject">
    <button
      :type="type"
      :data-test="selector"
      :disabled="disabled"
      @click="onClick"
      @focusin="onFocus"
      @mouseenter="onMouseEnter"
      @mouseleave="onMouseLeave"
      @blur="onBlur"
    >
      <div class="button__content">
        <Icon :icon="icon" v-if="iconPosition == 'left'" />
        <span class="label" v-if="text"><span v-html="text"></span></span>
        <Icon :icon="icon" v-if="iconPosition == 'right'" />
      </div>
      <LoadingBar v-if="loading" class="button__loading" :loading="loading" :transparent="true" />
    </button>
  </router-link>
</template>

<script>
import { ref, computed } from "vue";
import Icon from "./Icon.vue";
import LoadingBar from "./LoadingBar.vue";
export default {
  name: "Button",
  components: {
    Icon,
    LoadingBar
  },
  props: {
    text: { type: String, default: "" },
    to: { type: String, default: "" },
    icon: { type: String, default: null },
    iconPosition: { type: String, default: "left" },
    type: { type: String, default: "button" },
    color: { type: String, default: "primary" },
    kind: { type: String, default: "full" },
    block: { type: Boolean, default: false },
    selector: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false }
  },
  emits: ["blur", "click", "focus"],
  setup(props, context) {
    const isActive = ref(false);
    const isActivable = ref(true);

    const onClick = event => {
      context.emit("click", event);
    };
    const onFocus = event => {
      isActive.value = isActivable.value ? true : false;
      context.emit("focus", event);
    };
    const onMouseEnter = () => {
      isActivable.value = false;
      isActive.value = false;
    };
    const onMouseLeave = () => {
      isActivable.value = true;
    };
    const onBlur = event => {
      isActive.value = false;
      context.emit("blur", event);
    };

    const classObject = computed(() => {
      return {
        ["button--color-" + props.color]: !!props.color,
        ["button--kind-" + props.kind]: !!props.kind,
        "button--full-width": !!props.block,
        "button--is-active": !!isActive.value,
        "button--is-disabled": !!props.disabled
      };
    });

    return {
      isActive,
      isActivable,

      onClick,
      onFocus,
      onMouseEnter,
      onMouseLeave,
      onBlur,

      classObject
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/style/app.scss";

.button {
  @include edge;
  @include transition;
  position: relative;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  border: 2px solid transparent;
  outline: 0;
  text-align: center;
  padding: addSpace(2) addSpace(4);

  &:after {
    @include edge;
  }

  button {
    position: relative;
    z-index: z("front");
    background: transparent;
    color: inherit;
    padding: 0;
    margin: 0;
    outline: 0;
    border: none;
  }

  &--is-disabled {
    pointer-events: none;
    filter: grayscale(1) brightness(0.75);
  }

  .button__loading {
    position: absolute;
    left: addSpace(2);
    bottom: 0;
    width: calc(100% - #{addSpace(4)});
  }

  .button__content {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:not(.button--kind-inline) {
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &--kind-full {
    &.button--color-primary {
      background: $color-primary;

      &:hover {
        background-color: lighten($color-primary, 20%);
      }

      &:active {
        background-color: lighten($color-primary, 30%);
      }
    }
    &.button--color-secondary {
      background: $color-secondary;

      &:hover {
        background-color: lighten($color-secondary, 5%);
      }

      &:active {
        background-color: lighten($color-secondary, 10%);
      }
    }
    &.button--color-blue {
      background: $color-blue;

      &:hover {
        background-color: lighten($color-blue, 5%);
      }

      &:active {
        background-color: lighten($color-blue, 10%);
      }
    }
    &.button--color-black {
      background: $color-black;
      color: $color-white;

      &:hover {
        background-color: lighten($color-black, 5%);
      }

      &:active {
        background-color: lighten($color-black, 10%);
      }
    }
    &.button--color-white {
      background-color: $color-white;
      color: $color-black;

      &:hover {
        background-color: darken($color-white, 5%);
      }

      &:active {
        background-color: darken($color-white, 10%);
      }
    }
    &.button--color-error {
      background: $color-error;

      &:hover {
        background-color: lighten($color-error, 5%);
      }

      &:active {
        background-color: lighten($color-error, 10%);
      }
    }
    &.button--color-warning {
      background: $color-warning;

      &:hover {
        background-color: lighten($color-warning, 5%);
      }

      &:active {
        background-color: lighten($color-warning, 10%);
      }
    }
    &.button--color-success {
      background: $color-success;

      &:hover {
        background-color: lighten($color-success, 5%);
      }

      &:active {
        background-color: lighten($color-success, 10%);
      }
    }
  }

  &--kind-hollow {
    box-sizing: border-box;
    background-color: transparent;
    border: 2px solid;

    &.button--color-primary {
      border-color: $color-primary;

      &:hover {
        border-color: lighten($color-primary, 5%);
      }

      &:active {
        border-color: lighten($color-primary, 10%);
      }
    }
    &.button--color-secondary {
      border-color: $color-secondary;

      &:hover {
        border-color: lighten($color-secondary, 5%);
      }

      &:active {
        border-color: lighten($color-secondary, 10%);
      }
    }
    &.button--color-blue {
      border-color: $color-blue;

      &:hover {
        border-color: lighten($color-blue, 5%);
      }

      &:active {
        border-color: lighten($color-blue, 10%);
      }
    }
    &.button--color-black {
      border-color: $color-black;

      &:hover {
        border-color: lighten($color-black, 5%);
      }

      &:active {
        border-color: lighten($color-black, 10%);
      }
    }
    &.button--color-white {
      border-color: $color-white;

      &:hover {
        border-color: darken($color-white, 5%);
      }

      &:active {
        border-color: darken($color-white, 10%);
      }
    }
    &.button--color-gray {
      border-color: $color-gray;

      &:hover {
        border-color: lighten($color-gray, 5%);
      }

      &:active {
        border-color: lighten($color-gray, 10%);
      }
    }
    &.button--color-error {
      border-color: $color-error;

      &:hover {
        border-color: lighten($color-error, 5%);
      }

      &:active {
        border-color: lighten($color-error, 10%);
      }
    }
    &.button--color-warning {
      border-color: $color-warning;

      &:hover {
        border-color: lighten($color-warning, 5%);
      }

      &:active {
        border-color: lighten($color-warning, 10%);
      }
    }
    &.button--color-success {
      border-color: $color-success;

      &:hover {
        border-color: lighten($color-success, 5%);
      }

      &:active {
        border-color: lighten($color-success, 10%);
      }
    }
  }

  &--kind-link {
    border: none;
    background: transparent;
    text-decoration: underline;

    &.button--color-primary {
      color: $color-primary;

      &:hover {
        color: lighten($color-primary, 5%);
      }

      &:active {
        color: lighten($color-primary, 10%);
      }
    }
    &.button--color-secondary {
      color: $color-secondary;

      &:hover {
        color: lighten($color-secondary, 5%);
      }

      &:active {
        color: lighten($color-secondary, 10%);
      }
    }
    &.button--color-blue {
      color: $color-blue;

      &:hover {
        color: lighten($color-blue, 5%);
      }

      &:active {
        color: lighten($color-blue, 10%);
      }
    }
  }

  &--kind-inline {
    border: none;
    background: transparent;
    text-decoration: underline;
    padding: 0;

    &.button--color-primary {
      color: $color-primary;

      &:hover {
        color: lighten($color-primary, 5%);
      }

      &:active {
        color: lighten($color-primary, 10%);
      }
    }
    &.button--color-secondary {
      color: $color-secondary;

      &:hover {
        color: lighten($color-secondary, 5%);
      }

      &:active {
        color: lighten($color-secondary, 10%);
      }
    }
    &.button--color-blue {
      color: $color-blue;

      &:hover {
        color: lighten($color-blue, 5%);
      }

      &:active {
        color: lighten($color-blue, 10%);
      }
    }
  }

  &--kind-plain {
    color: inherit;
    background: transparent;
    border: none;
    padding: 0;
  }

  &--full-width {
    width: 100%;
  }

  .icon + .label,
  .label + .icon {
    margin-left: addSpace(1);
  }

  &--is-active:after {
    pointer-events: none;
    position: absolute;
    top: -1px;
    left: -1px;
    display: block;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    content: close-quote;
    border: 1px solid;
  }

  .label span {
    position: relative;
  }
  .icon {
    @include transition;
  }
}
</style>
