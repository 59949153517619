<template>
  <div class="loading-bar" :class="{ isLoading: loading, isTransparent: transparent }">
    <div class="loading-bar__container"><div class="loading-bar__content"></div></div>
  </div>
</template>

<script>
export default {
  name: "LoadingBar",
  props: {
    loading: { type: Boolean, default: false },
    transparent: { type: Boolean, default: false }
  }
};
</script>

<style scoped lang="scss">
@import "@/style/app.scss";

.loading-bar {
  @include transition;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 2px;

  &__container {
    @include fitContainer;
  }

  &__content {
    @include fitContainer;
    @include transition;
  }

  &.isLoading {
    background: darken($color-blue, 10%);

    .loading-bar__content {
      animation: loadingBarAnimation 2s linear infinite;
      background: $color-primary;
    }
  }
  &.isTransparent {
    background: transparent;
  }
}

@keyframes loadingBarAnimation {
  from {
    left: -30%;
    width: 30%;
  }

  50% {
    width: 30%;
  }

  70% {
    width: 70%;
  }

  80% {
    left: 50%;
  }

  95% {
    left: 120%;
  }

  to {
    left: 100%;
  }
}
</style>
