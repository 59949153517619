<template>
  <div class="input-field" :class="classObject">
    <label v-if="label" v-html="label"></label>
    <div class="hint" v-if="hint" v-html="hint"></div>
    <div class="input-field__wrapper">
      <div v-if="prefix" class="input-field__prefix">{{ prefix }}</div>
      <input
        class="input-field__input"
        :aria-label="label"
        :disabled="disabled"
        :inputmode="inputmode"
        :max="max"
        :min="min"
        :maxlength="maxlength"
        :minlength="minlength"
        :name="name"
        :pattern="pattern"
        :placeholder="placeholder"
        :readonly="readonly"
        ref="inputRef"
        :step="step"
        :tabindex="tabindex"
        :type="type"
        :value="modelValue"
        @keydown="onKeyDown"
        @input="onInput"
        @paste="onInput"
        @focusin="onFocus"
        @blur="onBlur"
      />
      <div v-if="suffix" class="input-field__suffix">{{ suffix }}</div>
      <div v-if="numberIcons" class="input-field__number-btn down" @click="numberKey(false)">
        <Icon :icon="currentNumberDownIcon" />
      </div>
      <div v-if="numberIcons" class="input-field__number-btn up" @click="numberKey(true)">
        <Icon :icon="currentNumberUpIcon" />
      </div>
      <Icon :icon="icon" class="input-field__icon" />
      <LoadingBar v-if="loading" class="input-field__loading" :loading="loading" :transparent="true" />
    </div>
    <div class="input-field__byline" v-if="byline" v-html="byline"></div>
    <transition name="maxH">
      <div
        class="input-field__validation-message"
        data-test="validation-message"
        v-if="errorMessage"
        v-html="$t(errorMessage, $props)"
      ></div>
    </transition>
  </div>
</template>

<script>
import { reactive, ref, computed, nextTick } from "vue";
import Functions from "@/functions";
import _ from "lodash";
import C from "@/assets/constants";
import Icon from "./Icon.vue";
import LoadingBar from "./LoadingBar.vue";
export default {
  name: "Input",
  components: {
    Icon,
    LoadingBar
  },
  props: {
    disabled: { type: Boolean, default: false },
    byline: { type: String, default: null },
    color: { type: String, default: "white" },
    forceinput: { type: [RegExp, String], default: null },
    hint: { type: String, default: null },
    icon: { type: String, default: null },
    iconPosition: { type: String, default: "right" },
    inputmode: { type: String, default: "text" },
    label: { type: String, default: null },
    loading: { type: Boolean, default: false },
    max: { type: [Number, Date, String], default: null },
    min: { type: [Number, Date, String], default: null },
    maxlength: { type: [Number, String], default: null },
    minlength: { type: [Number, String], default: null },
    modelValue: { type: [String, Number, Boolean], default: "" },
    name: { type: String, default: null },
    numberDirection: { type: String, default: "vertical" },
    numberDownIcon: { type: String, default: null },
    numberUpIcon: { type: String, default: null },
    numberIcons: { type: Boolean, default: false },
    pattern: { type: [String, RegExp], default: null },
    placeholder: { type: String, default: null },
    prefix: { type: String, default: null },
    readonly: { type: Boolean, default: false },
    suffix: { type: String, default: null },
    step: { type: [Number, String], default: null },
    tabindex: { type: Number, default: null },
    type: { type: String, default: "text" },
    validation: { type: Function, default: () => true },
    validation_message: { type: [String, Object], default: null }
  },
  emits: ["blur", "focus", "keydown", "update:modelValue", "valid"],
  setup(props, context) {
    const isActive = ref(false);
    const isChanged = ref(false);
    const isTouched = ref(false);
    const isValid = ref(true);
    const inputRef = ref(null);
    const formattedValue = ref("");
    const errorMessage = ref("");
    const lastKeyDown = reactive({});

    const focus = () => {
      inputRef.value.focus();
    };

    const selectContent = () => {
      inputRef.value.setSelectionRange(0, props.modelValue.length);
    };

    const validate = value => {
      Functions.Utils.toPromise(props.validation)(value)
        .then(valid => {
          isValid.value = !isTouched.value || valid === true;
          if (isValid.value) {
            errorMessage.value = "";
          } else {
            if (valid === false) {
              if (props.validation_message) {
                errorMessage.value = props.validation_message;
              } else {
                errorMessage.value = "";
              }
            } else {
              errorMessage.value = valid;
            }
          }
        })
        .catch(() => {
          isValid.value = false;
        })
        .finally(() => {
          context.emit("valid", isValid.value);
        });
    };

    const onKeyDown = event => {
      lastKeyDown.target = {
        value: _.get(event, "target.value"),
        selectionStart: _.get(event, "target.selectionStart"),
        selectionEnd: _.get(event, "target.selectionEnd")
      };
      context.emit("keydown", event);
    };
    const onInput = event => {
      if (event.keyCode == C.KEYCODE.TAB || event.keyCode == C.KEYCODE.SHIFT) return;
      isChanged.value = true;
      if (event.keyCode == C.KEYCODE.ENTER && event.key == "Enter") {
        isTouched.value = true;
      }
      if (props.forceinput) {
        let match = event.target.value.match(props.forceinput) || !_.get(event, "target.value.length");
        if (!match || match.input !== match[0]) {
          event.target.value = _.get(lastKeyDown, "target.value") || "";

          if (_.lowerCase(event.target.type) in C.InputTypeForSelectionAttr) {
            event.target.selectionStart = _.get(lastKeyDown, "target.selectionStart");
            event.target.selectionEnd = _.get(lastKeyDown, "target.selectionEnd");
          }
        }
      }
      context.emit("update:modelValue", event.target.value);
      nextTick(() => {
        validate(props.modelValue);
      });
    };
    const onFocus = event => {
      isActive.value = true;
      context.emit("focus", event);
    };
    const onBlur = event => {
      isActive.value = false;
      if (isChanged) {
        isTouched.value = true;
      }
      nextTick(() => {
        validate(props.modelValue);
      });
      context.emit("blur", event);
    };
    const numberKey = (up = false) => {
      let load = props.step ? parseFloat(props.step) : 1;
      load = up ? load : load * -1;
      load = Number(props.modelValue) + load;

      if (
        ((!props.min && props.min !== 0) || up || Number(props.min) <= load) &&
        (!props.max || !up || Number(props.max) >= load)
      ) {
        isTouched.value = true;
        context.emit("update:modelValue", load);
        nextTick(() => {
          validate(props.modelValue);
        });
      }
    };

    const isRequired = computed(() => _.isFunction(props.validation) && !props.validation.call("", context));
    const currentNumberUpIcon = computed(() => {
      if (props.numberIcons) {
        if (props.numberUpIcon) {
          return props.numberUpIcon;
        }
        return props.numberDirection == "vertical" ? "arrow-up" : "arrow-right";
      }
      return null;
    });
    const currentNumberDownIcon = computed(() => {
      if (props.numberIcons) {
        if (props.numberDownIcon) {
          return props.numberDownIcon;
        }
        return props.numberDirection == "vertical" ? "arrow-down" : "arrow-left";
      }
      return null;
    });
    const classObject = computed(() => {
      return {
        ["input-field--type-" + props.type]: !!props.type,
        "input-field--has-icon": props.icon,
        "input-field--has-prefix": props.prefix,
        "input-field--has-suffix": props.suffix,
        ["input-field--color-" + props.color]: !!props.color,
        "input-field--icon-left": props.iconPosition === "left",
        "input-field--is-active": !!isActive.value,
        "input-field--is-require": !!isRequired.value,
        "input-field--error": !isValid.value,
        "input-field--readonly": props.readonly,
        "input-field--disabled": props.disabled,
        ["input-field--numberDirection-" + props.numberDirection]: props.numberIcons
      };
    });

    return {
      isActive,
      isChanged,
      isTouched,
      isValid,
      inputRef,
      formattedValue,
      errorMessage,
      lastKeyDown,
      numberKey,

      focus,
      selectContent,
      validate,
      onKeyDown,
      onInput,
      onFocus,
      onBlur,

      isRequired,
      currentNumberUpIcon,
      currentNumberDownIcon,
      classObject
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/style/app.scss";

.input-field {
  width: 100%;
  margin-top: addSpace(2);
  margin-bottom: addSpace(2);

  label {
    display: block;
    margin-bottom: addSpace();
    text-align: left;
    margin-left: addSpace(3);
    font-size: $text-small-lead;
  }

  .hint {
    margin-left: addSpace(4);
    margin-bottom: addSpace();
  }

  &__wrapper {
    position: relative;
    &:after {
      @include transition();
      display: block;
      content: close-quote;
      position: absolute;
      pointer-events: none;
      opacity: 0;
      top: -1px;
      left: -1px;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      border: 1px solid $color-light;
      border-radius: 99px;
    }
  }

  &__icon {
    @include transition();
    position: absolute;
    top: 50%;
    right: addSpace(4);
    transform: translateY(-50%);
    pointer-events: none;
  }

  &--icon-left .input-field__icon {
    right: auto;
    left: addSpace(4);
  }

  &__input {
    @include transition();
    display: block;
    width: 100%;
    font-size: $text-note;
    padding: addSpace(2) addSpace(4);
    border: 1px solid transparent;
    outline: 0;
    outline-style: none;
    border-radius: 99px;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &::placeholder {
      color: getInputElementState(default, placeholderColor);
    }
    &:-ms-input-placeholder {
      color: getInputElementState(default, placeholderColor);
    }
  }

  &--has-icon .input-field__input {
    padding-right: addSpace(8);
  }

  &--icon-left .input-field__input {
    padding-right: addSpace(2);
    padding-left: addSpace(8);
  }

  &--has-prefix .input-field__input {
    padding-left: addSpace(4.5);
  }
  &--has-suffix .input-field__input {
    padding-right: addSpace(4.5);
  }
  &__prefix {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: addSpace(2);
  }
  &__suffix {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: addSpace(2);
  }

  &__byline {
    float: right;
    margin: addSpace(1) 0 0 addSpace(2);
  }

  &--color {
    &-blue {
      .input-field__input {
        color: $color-white;
        background: darken($color-blue, 5%);
      }
    }
    &-white {
      .input-field__input {
        color: $color-blue;
        background: $color-white;
      }
    }
    &-green,
    &-success {
      .input-field__input {
        color: $color-white;
        background: $color-success;
      }
    }
    &-error {
      .input-field__input {
        color: $color-white;
        background: $color-error;
      }
    }
  }

  &--type-number {
    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }
    input[type="number"] {
      -webkit-appearance: textfield;
      -moz-appearance: textfield;
      appearance: textfield;
    }
  }

  &__number-btn {
    @include transition();
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    top: pxToRem(1);
    right: pxToRem(1);
    width: addSpace(8);
    height: addSpace(4);
    text-align: center;
    cursor: pointer;
    user-select: none;
    &:hover {
      color: $color-primary;
    }
  }

  &--numberDirection-vertical {
    .input-field__input {
      padding-right: addSpace(6);
    }
    .input-field__number-btn {
      border-left: 1px solid rgba($color-black, 0.1);
    }
    .input-field__number-btn.up {
      border-bottom: 1px solid rgba($color-black, 0.1);
    }
    .input-field__number-btn.down {
      top: auto;
      bottom: pxToRem(1);
    }
  }
  &--numberDirection-horizontal {
    .input-field__input {
      padding-left: addSpace(7);
      padding-right: addSpace(7);
    }
    .input-field__number-btn {
      width: addSpace(5);
      height: calc(100% - 2px);
      top: pxToRem(1);
    }
    .input-field__number-btn.up {
      border-left: 1px solid darken($color-blue, 10%);
    }
    .input-field__number-btn.down {
      border-right: 1px solid darken($color-blue, 10%);
      right: auto;
      left: pxToRem(1);
    }
  }

  &--is-active {
    .input-field__wrapper:after {
      opacity: 1;
    }

    .input-field__input {
      color: getInputElementState(active, inputColor);
      border-color: getInputElementState(default, borderColor);
    }

    .input-field__icon {
      color: getInputElementState(active, iconFillActive);
    }
  }

  &--is-require {
    label::after {
      content: " *";
      color: $color-error;
    }
  }

  &--success {
    .input-field__input,
    .input-field__wrapper:after {
      color: getInputElementState(success, inputColor);
      border-color: getInputElementState(success, borderColor) !important;
    }
  }

  &--error {
    .input-field__input,
    .input-field__wrapper:after {
      color: $color-error;
      border-color: $color-error !important;
    }

    .input-field__icon {
      color: $color-error;
    }
  }

  &--readonly {
    pointer-events: none;
    .input-field__input {
      border-color: getInputElementState(disabled, borderColor);
    }
  }

  &--disabled {
    pointer-events: none;

    .input-field__input {
      color: getInputElementState(disabled, inputColor);
      border-color: getInputElementState(disabled, borderColor);
      background-color: getInputElementState(disabled, backgroundColor);

      &[type="range"] {
        background-color: transparent;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        &::-webkit-slider-runnable-track {
          background-color: getInputElementState(disabled, rangeColor);
        }

        &::-moz-range-progress {
          background-color: getInputElementState(disabled, rangeFillColor);
        }

        &::-moz-range-track {
          background-color: getInputElementState(disabled, rangeColor);
        }

        &::-ms-fill-lower {
          background-color: getInputElementState(disabled, rangeFillColor);
        }

        &::-ms-fill-upper {
          background-color: getInputElementState(disabled, rangeColor);
        }
      }
    }

    .input-field__prefix,
    .input-field__suffix {
      color: getInputElementState(disabled, inputColor);
    }
    .input-field__range-fill {
      background: getInputElementState(disabled, rangeFillColor);
    }
  }

  &__loading {
    position: absolute !important;
    bottom: 0;
    left: addSpace(3);
    width: calc(100% - #{addSpace(6)});
    border-radius: 1em;
  }

  &__validation-message {
    text-align: left;
    padding-top: addSpace(2);
    padding-left: addSpace(3);
    color: $color-error;
  }
}
</style>
